<template>
  <div class="px-4 max-w-screen-xl mx-auto mb-20 pt-20">
    <div class="flex justify-center items-center">
      <h1 class="text-4xl max-w-screen-sm text-center my-8">TARIF 2022</h1>
    </div>
    <div>
      <h2 class="underline decoration-4 decoration-blue-400 my-8 text-2xl">PÊCHE EN MER au départ de Port de Bouc 13110</h2>
    </div>
    <div class="space-y-8">
      <div data-aos="fade-up" class="flex flex-col justify-center items-center sm:flex-row md:space-x-8 sm:justify-between">
        <div class="lg:flex space-x-4">
          <p>PRIVATISATION BÂTEAU DEMI-JOURNÉE</p>
          <p>Maquereaux – Bonites</p>
        </div>
        <p class="text-blue-400 text-lg">400.00 (maxi 5 pers)</p>
      </div>
      <div data-aos="fade-up" class="flex flex-col justify-center items-center sm:flex-row md:space-x-8 sm:justify-between">
        <div class="lg:flex space-x-4">
          <p>PRIVATISATION BÂTEAU DEMI-JOURNÉE</p>
          <p>Daurades – Loups</p>
        </div>
        <p class="text-blue-400 text-lg">450.00 (maxi 5 pers)</p>
      </div>
      <div data-aos="fade-up" class="flex flex-col justify-center items-center sm:flex-row md:space-x-8 sm:justify-between">
        <div class="lg:flex space-x-4">
          <p>PRIVATISATION BÂTEAU JOURNÉE</p>
          <p>Tous types de pêche</p>
        </div>
        <div>
          <p class="text-blue-400 text-lg">500.00 / 3 pers</p>
          <p class="text-blue-400 text-lg">600.00 / 4 pers</p>
          <p class="text-blue-400 text-lg">700.00 / 5 pers</p>
        </div>
      </div>
      <div data-aos="fade-up" class="flex flex-col justify-center items-center sm:flex-row md:space-x-8 sm:justify-between">
        <p>PÊCHEUR SEUL OU À DEUX</p>
        <p class="text-blue-400 text-lg">150.00 / pers</p>
      </div>
      <div data-aos="fade-up" class="flex flex-col justify-center items-center sm:flex-row md:space-x-8 sm:justify-between">
        <p>SÉJOUR PÊCHE 2 JOURNÉES TOUT INCLUS (2 repas du midi sur le bateau – 1 repas du soir au restaurant – 1 petit déjeuner + 1 nuitée)</p>
        <p class="text-blue-400 text-lg">490.00 / pers</p>
      </div>
    </div>
    <div>
      <h2 class="underline decoration-4 decoration-blue-400 my-8 text-2xl">PÊCHE EAU DOUCE au départ d’Esparron de Verdon</h2>
    </div>
    <div class="space-y-8">
      <div data-aos="fade-up" class="flex flex-col justify-center items-center sm:flex-row md:space-x-8 sm:justify-between">
        <div class="lg:flex space-x-4">
          <p>PRIVATISATION BÂTEAU JOURNÉE</p>
          <p>Pêche du carnassier</p>
        </div>
        <div>
          <p class="text-blue-400 text-lg">300.00 / 2 pers</p>
          <p class="text-blue-400 text-lg">400.00 / 3 pers</p>
        </div>
      </div>
      <div data-aos="fade-up" class="flex flex-col justify-center items-center sm:flex-row md:space-x-8 sm:justify-between">
        <p>SÉJOUR PÊCHE 2 JOURNÉES TOUT INCLUS (2 repas du midi sur le bateau – 1 repas du soir au restaurant – 1 petit déjeuner + 1 nuitée)</p>
        <p class="text-blue-400 text-lg">490.00 / pers</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>